body {
  margin: 0;
  font-family: '-apple-system', 'BlinkMacSystemFont', 'Roboto', 'Oxygen', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Brayden Script';
  src: local('Brayden Script'), url(./Assets/Brayden-Script-Regular.otf) format('otf');
}

html {
  background-color: rgb(34, 34, 59);
  color: white;
}

a {
  color: white;
  text-decoration: none;
}

p {
  line-height: 1.3;
}

h1 {
  font-size: 50px;
  margin-bottom: -15px;
}

h2 {
  font-size: 20px;
  opacity: 0.7;
  margin-left: 85px;
  display:inline-flex;
}

@media only screen and (max-width: 420px) {
  h2 {
    font-size: 20px;
    opacity: 0.7;
    margin-left: 0px;
    display:inline-flex;
  }
}

.logo {
  font-family: 'Brayden Script';
  font-size: 60px;
}

.logo:hover {
  opacity: 0.7;
  transition-duration: 0.5s;
}

.js-color-stop {
  position: relative;
  color: white;
}

.icons {
  padding: 50px
}

.icon {
  margin: 10px
}

.icon:hover {
  opacity: 0.7;
  transition-duration: 0.5s;
}

.greeting {
  padding: 6rem;
}

@media screen and (max-width: 420px) {
  .greeting {
    padding: 2rem;
  }
}

.footer {
  position: absolute;
  text-align: center;
  top: 70vh;
  padding-bottom: 30px;
  width: 100%;
}

@media screen and (max-width: 420px) {
  .footer {
    position: absolute;
    text-align: center;
    top: 270vh;
    padding-bottom: 20px;
    width: 100%;
    z-index: 3;
  }
}

.about-me {
  /* padding-top: 40px; */
  font-size: 18px;
}

@media screen and (max-width: 420px) {
  .about-me {
    position: absolute;
    top: 130vh;
    font-size: 18px;
    z-index: 2;
  }
}

.bio {
  font-size: 18px;
}

.recommendation {
  margin: auto;
}

.more-projects {
  position: absolute;
  text-align: center;
  opacity: 0.7;
  top: 97vh;
  transform: translateY(-100%);
  width: 100%;
}

.subtitle {
  margin-top: 50px;
  font-size: 18px;
}

.subtitle-extra-space {
  margin-top: 105px;
  font-size: 18px;
}

@media screen and (max-width: 420px) {
  .subtitle-extra-space {
    margin-top: 50px;
    font-size: 18px;
  }
}

.projects {
  margin-top: 20px;
}

@media screen and (max-width: 420px) {
  img {
    width: 100%;
  }
}

@media screen and (max-width: 420px) {
  p {
    font-size: 18px;
  }
}

.desc {
  margin-top: 50px;
  line-height: 1.6;
}

hr {
  margin-top: 40px;
  margin-bottom: 40px;
  height: 1px;
  background-color: black;
  border: none;
  box-shadow: 0px 3px 5px black;
}

li {
  margin-top: 15px;
  margin-bottom: 15px;
}

.centered {
  font-size: 30px;
  text-align: center;
  color: rgb(34, 34, 59);;
}